export async function delay (
  ms: number,
  timerRef: Ref<NodeJS.Timeout | null> | null = null
): Promise<void> {
  await new Promise<void>((resolve) => {
    const newTimer = setTimeout(() => {
      resolve();
    }, ms);

    if (timerRef) {
      if (timerRef.value) {
        clearTimeout(timerRef.value);
      }
      timerRef.value = newTimer;
    }
  });
}
