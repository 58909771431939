import { UseFetchOptions } from 'nuxt/app';

export const useAuthenticationHeaders = async <T>(opts: UseFetchOptions<T>) => {
  const userStore = useUserStore(useNuxtApp().$pinia);

  const token = await userStore.getAuthToken();

  if (token && token.length > 0) {
    opts.headers = {
      ...opts.headers,
      Authorization: `Bearer ${token}`
    };
  }

  opts.query = {
    ...opts.query,
    userId: userStore.uid
  };
};
