import { UseFetchOptions } from 'nuxt/app';

export async function useAppFetch<T>(
  url: string,
  options: UseFetchOptions<T> = {},
  modifyAppState = true
) {
  const { data } = await useAsyncData(
    url,
    async () => {
      await useAuthenticationHeaders<T>(options);

      const appStore = modifyAppState
        ? useAppStore(useNuxtApp().$pinia)
        : null;

      options.onRequest = () => {
        appStore?.setLoading(true);
      };

      options.onResponse = () => {
        appStore?.setLoading(false);
      };

      options.onResponseError = (ctx) => {
        appStore?.setError(
          createError({
            statusMessage: ctx.error?.message
          })
        );
      };

      const { data } = await useFetch(url, options);

      return data.value;
    }
  );

  return data.value;
}
