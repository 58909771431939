<script setup lang="ts">
import { Loader } from '@genoch/genoch-ui-web';

const appStore = useAppStore();
const { loading, error } = storeToRefs(appStore);
</script>

<template>
  <NuxtLayout>
    <NuxtLoadingIndicator />
    <StateError v-if="error" :error="error" />
    <Loader v-if="loading" />
    <NuxtPage />
  </NuxtLayout>
</template>
