import { NuxtError } from 'nuxt/app';

export const useAppStore = defineStore('app', () => {
  // * State
  const runningTimer = ref<NodeJS.Timeout | null>(null);

  const _loading: Ref<Boolean> = ref(false);
  const _error: Ref<NuxtError | null> = ref(null);

  // * Functions
  function setLoading(loading: Boolean) {
    _loading.value = loading;
  }

  function setError(error: NuxtError | null, timeout = 5000) {
    _error.value = error;

    if (
      error?.statusMessage === 'Missing user ID' ||
      error?.statusMessage === 'Unauthorized'
    ) {
      const { user } = storeToRefs(useUserStore());
      user.value = null;
      navigateTo('/user/signin');
    }

    // Clear the timer if it's already running
    if (runningTimer.value) {
      clearTimeout(runningTimer.value);
      runningTimer.value = null;
    }

    // Set a new timer
    delay(timeout, runningTimer).then(() => {
      _error.value = null;
    });
  }

  return { loading: _loading, error: _error, setLoading, setError };
});
