import { FirebaseApp, initializeApp } from '@firebase/app';
import { initializeAuth, browserSessionPersistence, Auth } from '@firebase/auth';
import { defineNuxtPlugin } from '#app';
import firebaseConfig from '../config/firebase.json';

export default defineNuxtPlugin(() => {
  const app: FirebaseApp | undefined = initializeApp(firebaseConfig);
  const auth: Auth | undefined = initializeAuth(app, {
    persistence: browserSessionPersistence
  });

  return {
    provide: {
      firebase: app,
      firebaseAuth: auth
    }
  };
});
