import { UserRole } from '~/models/user';

export default defineNuxtRouteMiddleware((to) => {
  if (to.path === '/user/signin') { return; }

  const { user, uid } = useUserStore(useNuxtApp().$pinia);

  if (user === null || uid === null) {
    return navigateTo('/user/signin');
  }

  // User is logged in, continue

  if (to.path.startsWith('/admin') && user?.role !== UserRole.ADMIN) {
    return navigateTo('/client');
  }

  // User is admin, continue
});
