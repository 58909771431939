<script setup lang="ts">
import { NuxtError } from 'nuxt/app';

const props = defineProps({
  error: {
    type: Object as PropType<NuxtError>,
    required: true
  },
  timeoutMs: {
    type: Number,
    default: 5000
  }
});

const timeoutMsStyle = ref(`${props.timeoutMs}ms`);
</script>

<template>
  <div v-if="error !== null" class="error-message">
    <p>
      {{ error?.statusMessage }}
    </p>
    <span class="timeout-indicator" />
  </div>
</template>

<style scoped>
.error-message {
    @apply fixed bottom-0 right-0 mb-8 mr-8 overflow-hidden;
    @apply flex flex-col justify-stretch z-[999];
    @apply bg-gradient-to-b from-yellow-100 to-yellow-200 rounded-2xl;
}

.error-message>p {
    @apply px-8 py-4 text-sm font-medium;
}

.timeout-indicator {
    @apply w-full h-[6px] bg-gray-800 rounded-full;
    animation: scale-x v-bind(timeoutMsStyle) infinite;
    animation-timing-function: linear;
    transform-origin: 0;
}

@keyframes scale-x {
    0% {
        transform: scaleX(1);
    }

    100% {
        transform: scaleX(0);
    }
}
</style>
