import { default as indexjePgU5A16mMeta } from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/admin/docs/index.vue?macro=true";
import { default as indexPSdqxNcKBFMeta } from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/admin/index.vue?macro=true";
import { default as _91uid_93Ac2qSADzQ5Meta } from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/admin/users/[uid].vue?macro=true";
import { default as new0v23qtA9SyMeta } from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/admin/users/new.vue?macro=true";
import { default as indexqJFiea90WTMeta } from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/client/[uid]/shop/[projectId]/category/index.vue?macro=true";
import { default as indexW7mLSNrPPuMeta } from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/client/[uid]/shop/[projectId]/index.vue?macro=true";
import { default as indexO5OLAyoqqGMeta } from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/client/[uid]/shop/[projectId]/product/index.vue?macro=true";
import { default as newNuScNeWt4wMeta } from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/client/[uid]/shop/[projectId]/product/new.vue?macro=true";
import { default as index1EsUTnGfMjMeta } from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/client/[uid]/shop/index.vue?macro=true";
import { default as indext9ZYZFXHm5Meta } from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/client/index.vue?macro=true";
import { default as indextRsnv1LPM6Meta } from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/index.vue?macro=true";
import { default as profileMqWkLmCfVsMeta } from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/user/profile.vue?macro=true";
import { default as signinBMnyieNA9lMeta } from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/user/signin.vue?macro=true";
export default [
  {
    name: indexjePgU5A16mMeta?.name ?? "admin-docs",
    path: indexjePgU5A16mMeta?.path ?? "/admin/docs",
    meta: indexjePgU5A16mMeta || {},
    alias: indexjePgU5A16mMeta?.alias || [],
    redirect: indexjePgU5A16mMeta?.redirect || undefined,
    component: () => import("/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/admin/docs/index.vue").then(m => m.default || m)
  },
  {
    name: indexPSdqxNcKBFMeta?.name ?? "admin",
    path: indexPSdqxNcKBFMeta?.path ?? "/admin",
    meta: indexPSdqxNcKBFMeta || {},
    alias: indexPSdqxNcKBFMeta?.alias || [],
    redirect: indexPSdqxNcKBFMeta?.redirect || undefined,
    component: () => import("/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: _91uid_93Ac2qSADzQ5Meta?.name ?? "admin-users-uid",
    path: _91uid_93Ac2qSADzQ5Meta?.path ?? "/admin/users/:uid()",
    meta: _91uid_93Ac2qSADzQ5Meta || {},
    alias: _91uid_93Ac2qSADzQ5Meta?.alias || [],
    redirect: _91uid_93Ac2qSADzQ5Meta?.redirect || undefined,
    component: () => import("/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/admin/users/[uid].vue").then(m => m.default || m)
  },
  {
    name: new0v23qtA9SyMeta?.name ?? "admin-users-new",
    path: new0v23qtA9SyMeta?.path ?? "/admin/users/new",
    meta: new0v23qtA9SyMeta || {},
    alias: new0v23qtA9SyMeta?.alias || [],
    redirect: new0v23qtA9SyMeta?.redirect || undefined,
    component: () => import("/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/admin/users/new.vue").then(m => m.default || m)
  },
  {
    name: indexqJFiea90WTMeta?.name ?? "client-uid-shop-projectId-category",
    path: indexqJFiea90WTMeta?.path ?? "/client/:uid()/shop/:projectId()/category",
    meta: indexqJFiea90WTMeta || {},
    alias: indexqJFiea90WTMeta?.alias || [],
    redirect: indexqJFiea90WTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/client/[uid]/shop/[projectId]/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexW7mLSNrPPuMeta?.name ?? "client-uid-shop-projectId",
    path: indexW7mLSNrPPuMeta?.path ?? "/client/:uid()/shop/:projectId()",
    meta: indexW7mLSNrPPuMeta || {},
    alias: indexW7mLSNrPPuMeta?.alias || [],
    redirect: indexW7mLSNrPPuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/client/[uid]/shop/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexO5OLAyoqqGMeta?.name ?? "client-uid-shop-projectId-product",
    path: indexO5OLAyoqqGMeta?.path ?? "/client/:uid()/shop/:projectId()/product",
    meta: indexO5OLAyoqqGMeta || {},
    alias: indexO5OLAyoqqGMeta?.alias || [],
    redirect: indexO5OLAyoqqGMeta?.redirect || undefined,
    component: () => import("/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/client/[uid]/shop/[projectId]/product/index.vue").then(m => m.default || m)
  },
  {
    name: newNuScNeWt4wMeta?.name ?? "client-uid-shop-projectId-product-new",
    path: newNuScNeWt4wMeta?.path ?? "/client/:uid()/shop/:projectId()/product/new",
    meta: newNuScNeWt4wMeta || {},
    alias: newNuScNeWt4wMeta?.alias || [],
    redirect: newNuScNeWt4wMeta?.redirect || undefined,
    component: () => import("/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/client/[uid]/shop/[projectId]/product/new.vue").then(m => m.default || m)
  },
  {
    name: index1EsUTnGfMjMeta?.name ?? "client-uid-shop",
    path: index1EsUTnGfMjMeta?.path ?? "/client/:uid()/shop",
    meta: index1EsUTnGfMjMeta || {},
    alias: index1EsUTnGfMjMeta?.alias || [],
    redirect: index1EsUTnGfMjMeta?.redirect || undefined,
    component: () => import("/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/client/[uid]/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indext9ZYZFXHm5Meta?.name ?? "client",
    path: indext9ZYZFXHm5Meta?.path ?? "/client",
    meta: indext9ZYZFXHm5Meta || {},
    alias: indext9ZYZFXHm5Meta?.alias || [],
    redirect: indext9ZYZFXHm5Meta?.redirect || undefined,
    component: () => import("/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/client/index.vue").then(m => m.default || m)
  },
  {
    name: indextRsnv1LPM6Meta?.name ?? "index",
    path: indextRsnv1LPM6Meta?.path ?? "/",
    meta: indextRsnv1LPM6Meta || {},
    alias: indextRsnv1LPM6Meta?.alias || [],
    redirect: indextRsnv1LPM6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/index.vue").then(m => m.default || m)
  },
  {
    name: profileMqWkLmCfVsMeta?.name ?? "user-profile",
    path: profileMqWkLmCfVsMeta?.path ?? "/user/profile",
    meta: profileMqWkLmCfVsMeta || {},
    alias: profileMqWkLmCfVsMeta?.alias || [],
    redirect: profileMqWkLmCfVsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: signinBMnyieNA9lMeta?.name ?? "user-signin",
    path: signinBMnyieNA9lMeta?.path ?? "/user/signin",
    meta: signinBMnyieNA9lMeta || {},
    alias: signinBMnyieNA9lMeta?.alias || [],
    redirect: signinBMnyieNA9lMeta?.redirect || undefined,
    component: () => import("/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/pages/user/signin.vue").then(m => m.default || m)
  }
]