import { defineNuxtPlugin } from '#app/nuxt'
import { LazySegmentedControl, LazyFormCalendar, LazyFormCheckbox, LazyFormInput, LazyFormSelection, LazyFormText } from '#components'
const lazyGlobalComponents = [
  ["SegmentedControl", LazySegmentedControl],
["FormCalendar", LazyFormCalendar],
["FormCheckbox", LazyFormCheckbox],
["FormInput", LazyFormInput],
["FormSelection", LazyFormSelection],
["FormText", LazyFormText]
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
