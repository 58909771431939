enum UserRole {
    ADMIN = 'admin',
    CLIENT = 'client',
}

enum UserAccess {
  FULL = 'full',
  READ = 'read',
  NONE = 'none'
}

namespace UserAccess {
    export function toNumber(access: UserAccess): number {
      switch (access) {
        case UserAccess.FULL:
          return 2;
        case UserAccess.READ:
          return 1;
        default:
          return 0;
      }
    }

    export function fromNumber(access: number): UserAccess | undefined {
      switch (access) {
        case 2:
          return UserAccess.FULL;
        case 1:
          return UserAccess.READ;
        case 0:
          return UserAccess.NONE;
        default:
          return undefined;
      }
    }

    export function hasAccess(access: UserAccess, requiredAccess: UserAccess): boolean {
      return toNumber(access) >= toNumber(requiredAccess);
    }

    export const names = Object.values(UserAccess).filter(v => typeof v === 'string') as string[];
  }

interface UserData {
    id: string

    profileUrl?: string
    displayName?: string

    role: UserRole
    access: { [projectId: string]: UserAccess }
}

export { UserRole, UserAccess, UserData };
