import revive_payload_client_4sVQNw7RlN from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import firebase_client_AZGfnMpR9m from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/modules/genoch-nuxt/src/runtime/plugins/firebase.client.ts";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import genoch_ui_web_client_fQMuHhjnqI from "/home/runner/work/genoch-mercato-client-dashboard/genoch-mercato-client-dashboard/plugins/genoch-ui-web.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  firebase_client_AZGfnMpR9m,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  genoch_ui_web_client_fQMuHhjnqI
]