import { signInWithEmailAndPassword } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { UserData } from '@/models/user';

export const useUserStore = defineStore('user', () => {
  // * State
  const _firebaseUser = computed(() => useNuxtApp().$firebaseAuth?.currentUser);

  const user = ref<UserData | null>(null);
  const uid = computed(() => user.value?.id ?? _firebaseUser.value?.uid ?? null);

  const getAuthToken = () => useNuxtApp().$firebaseAuth?.currentUser?.getIdToken();

  //* Functions

  async function signIn(user: { email: string, password: string }) {
    const auth = useNuxtApp().$firebaseAuth;

    if (!auth) { return; }

    try {
      await signInWithEmailAndPassword(
        auth,
        user.email,
        user.password
      );
    } catch (error) {
      const firebaseError = error as FirebaseError;
      useAppStore().setError(
        createError({
          statusCode: 500,
          statusMessage: firebaseError.message
        })
      );
      navigateTo('/user/signin');

      return;
    }

    if (!_firebaseUser) {
      useAppStore().setError(
        createError({
          statusCode: 500,
          statusMessage: 'Unable to sign in, please try again'
        })
      );

      navigateTo('/user/signin');

      return;
    }

    await getUserData(false);
    navigateTo('/');
  }

  async function update(data: { displayName: string, photoURL: string }) {
    const updatedUser = await useAppFetch<UserData>(
      '/api/v1/client/user/update',
      {
        method: 'POST',
        body: JSON.stringify(data)
      }
    );

    if (updatedUser) {
      user.value = updatedUser;
    }
  }

  async function signOut() {
    const auth = useNuxtApp().$firebaseAuth;
    if (!user.value && auth.currentUser === null) {
      navigateTo('/user/signin');
      return;
    }

    try {
      await auth.signOut();
    } catch (error) {
      const firebaseError = error as FirebaseError;
      useAppStore().setError(
        createError({
          statusCode: 500,
          statusMessage: firebaseError.message
        })
      );
      navigateTo('/user/signin');
      return;
    }

    user.value = null;
    navigateTo('/user/signin');
  }

  async function getUserData(cache = true) {
    if (cache && user.value) {
      return user.value;
    }

    const userData = await useAppFetch<UserData>('/api/v1/client/user/data');

    if (userData) {
      user.value = userData;
      return userData;
    }

    return null;
  }

  return { user, uid, signIn, signOut, update, getUserData, getAuthToken };
}, {
  persist: true
});
